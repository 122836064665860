import React, { useState, useRef, useEffect } from "react"
import "./header.css"

import { Link } from "react-router-dom"
import img from '../../../assets/image/callcentre.jpg'

const Header = () => {
  const [navList, setNavList] = useState(false)

  
 

  return (
    <>
    <header>

    <div className='container flex'>
      <div className='logo'>
        
       <Link to="/" ><img src={'./images/logo.png'} alt='' /></Link>
      </div>
      <div className='nav'>

      <ul className={navList ? "small" : "flex"}>
      <li><Link to="/" >Home</Link></li>


      <li className="dropdown"><Link to="/about"><span>About FRT</span> <i className="bi bi-chevron-right"></i></Link>
        <ul>
        <li className="dropdown"><Link to="/about"><span>About Us</span> <i className="bi bi-chevron-right"></i></Link>
          
        <ul>
          <li><Link to="/about">What Are We?</Link></li>
          <li><Link to="/historytimeline">Our History</Link></li>
          <li><Link to="/mission">Our Mission and Vision</Link></li>


       </ul>         
        </li>
          <li className="dropdown"><Link to="/"><span>Our Team</span> <i className="bi bi-chevron-right"></i></Link>
          
          <ul>
            <li><Link to="/executive">The Executive Team</Link></li>
            <li><Link to="/trustee">Board of Trustees</Link></li>
            <li><Link to="/staff">Our Staff</Link></li>

         </ul>
          
          </li>
          <li><Link to="/projects">Projects</Link></li>
          <li><Link to="/">Feedback</Link></li>
          <li><Link to="/">Strategic Direction</Link></li>
          <li className="dropdown"><Link to="/"><span>Policy</span> <i className="bi bi-chevron-right"></i></Link>
            <ul>
              <li><Link to="/">Privacy Policy</Link></li>
              <li><Link to="/policy/mlimi-app-polocy">Mlimi App Policy</Link></li>
            
            </ul>
          </li>
        </ul>
      </li>

      <li className="dropdown"><Link to="/"><span>News &amp; Media</span> <i className="bi bi-chevron-down"></i></Link>
        <ul>
          <li><Link to="/publications">Latest News</Link></li>
          <li><Link to="/publications">Publications</Link></li>
          <li><Link to="/">Events</Link></li>



        </ul>
      </li>
      <li className="dropdown"><Link to="/services"><span>Our Works</span> <i className="bi bi-chevron-down"></i></Link>
      <ul>
        <li><Link to="/services">Work With Us</Link></li>


      </ul>
    </li>
    
      
      <li className="dropdown"><Link to="/services"><span>Services</span> <i className="bi bi-chevron-down"></i></Link>
        <ul>
          <li><Link to="/services">What We Do</Link></li>
          <li><Link to="/">Weather Updates</Link></li>
          <li className="dropdown"><Link to="/"><span>Farmers’ Hub</span> <i className="bi bi-chevron-right"></i></Link>
          

          
          </li>
          <li><Link to="/">Opportunities and Careers</Link></li>

        </ul>
      </li>

      <Link to="/projects"><li>Projects</li></Link>
      <Link to="/contact"><li>Contact</li></Link>



    </ul>

      </div>
      <form className='flex'>
      <div className='social'>
        
      <a href="https://web.facebook.com/FarmRadioMw"
      rel="noopener" target="_blank"><img src='./images/facebook.png' alt='' /> </a>
     
     </div>
     <div className='social'>
        
     <a href="https://www.youtube.com/@farmradiotrust846"
     rel="noopener" target="_blank"><img src='./images/youtube.png' alt='' /> </a>
    </div>
    <div className='social'>
        
    <a href="https://twitter.com/FarmRadioMw"
    rel="noopener" target="_blank"><img src='./images/twitter.png' alt='' /> </a>
    </div>
      
      </form>
      

  
      

      <div className='toggle  mobile-nav-toggle'>
        <button onClick={() => setNavList(!navList)}>{navList ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>}</button>
      </div>
    </div>
  </header>
    </>
  )
}

export default Header
