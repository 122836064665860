import React, { useEffect, useState } from 'react'
import "./publications.css"
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom"
import Header from '../../common/header/Header'


const Publications = () => {
  const [articles, setArticles] =useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 8;
  
  

  useEffect(() => {
    // Fetch data from JSON file in the public folder
    fetch("/articles.json")
      .then((response) => response.json())
      .then((data) => {
        // Filter articles where type is 'Publication'
        const filteredArticles = data.filter(article => article.type === "Publication");
        setArticles(filteredArticles);
      })
      .catch((error) => {
        console.error("Error fetching the articles data: ", error);
      });
  }, []);

  // Logic for pagination
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

  const totalPages = Math.ceil(articles.length / articlesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
    <Header/>
    <section id="blog" className="blog">
    <div className="container " data-aos="fade-up">

      <div className="row">

        <div className="col-lg-8 entries">
        {currentArticles.map((article) => (
          <article className="entry" key={article.id}>
            <div className="entry-img">
              <img src={article.image} alt="" className="img-fluid" />
            </div>
            <h2 className="entry-title">
              <Link to={article.readMoreLink}>{article.title}</Link>
            </h2>
            <div className="entry-meta">
              <ul>
                <li className="d-flex align-items-center">
                  <i className="bi bi-person"></i> 
                  <a href="blog-single.html">{article.author}</a>
                </li>
                <li className="d-flex align-items-center">
                  <i className="bi bi-clock"></i> 
                  <a href="blog-single.html"><time dateTime="2020-01-01">{article.date}</time></a>
                </li>
                <li className="d-flex align-items-center">
                  <i className="bi bi-chat-dots"></i> 
                  <a href="blog-single.html">{article.comments}</a>
                </li>
              </ul>
            </div>
            <div className="entry-content">
              <p>{article.description}</p>
              <div className="read-more my-5">
                <Link to={article.readMoreLink}>Read More</Link>
                <a className='ml-3' href={article.downloadLink} download={article.downloadLink}>
                  Download
                </a>
              </div>
            </div>
          </article>
        ))}



        <article className="entry">

        <div className="entry-img">
        <img src="./image/blog/blog-2.jpg" alt="" className="img-fluid"/>

        {/*<video src={movie}  loop controls/>*/}

        </div>

        <h2 className="entry-title">
          <a href="blog-single.html">FRT and NPC have signed a Memorandum of Understanding (MoU) </a>
        </h2>
      
        <div className="entry-meta">
          <ul>
            <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
            <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">July 23, 2023</time></a></li>
            <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">16 Comments</a></li>
          </ul>
        </div>

        <div className="entry-content">
          <p>
          Farm Radio Trust (FRT) and  The National Planning  Commission (NPC) have signed a Memorandum of Understanding (MoU) to upscale awareness about the Malawi 2063 agenda.
          </p>
          <div className="read-more">
            <a href="blog-single.html">Read More</a>
          </div>
        </div>

      </article>
      <article className="entry">

      <div className="entry-img">

      <img src="./image/blog/blog-3.jpg" alt="" className="img-fluid"/>

      </div>

      <h2 className="entry-title">
        <a href="blog-single.html">Mlimi Radio Launch</a>
      </h2>
      
      <div className="entry-meta">
        <ul>
          <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
          <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">March 25, 2023</time></a></li>
          <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
        </ul>
      </div>

      <div className="entry-content">
        <p>
        Finally, Mlimi Radio, M'godi wa Uthenga, has officially been launched today.officially launched on 25th March 2023 by His Excellency Mr. Matthias Diependael (Minister of the Government of Flanders)
          </p>
        <div className="read-more">
          <a href="blog-single.html">Read More</a>
        </div>
      </div>

    </article>
      
      <article className="entry">

        <div className="entry-img">

          <ReactPlayer width={'100%'}  className='youtubev' url={'https://www.youtube.com/watch?v=8yz6yRFFG-o'} controls={true}   />

        </div>

        <h2 className="entry-title">
          <a href="blog-single.html">Radio as a COVID prevention tool for farmers in Malawi</a>
        </h2>
        
        <div className="entry-meta">
          <ul>
            <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
            <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jal 20, 2020</time></a></li>
            <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
          </ul>
        </div>

        <div className="entry-content">
          <p>
          Radio being used as of the great tools to pass information to the Farmers during the Covid 19 Pandemic
            </p>
          <div className="read-more">
            <a href="blog-single.html">Read More</a>
          </div>
        </div>

      </article>

      <article className="entry">

      <div className="entry-img">

        <ReactPlayer width={'100%'}  className='youtubev' url={'https://youtu.be/a9yxShslXhE'} controls={true}   />         </div>

      <h2 className="entry-title">
        <a href="blog-single.html">Radio as a COVID prevention tool for farmers in Malawi</a>
      </h2>
      
      <div className="entry-meta">
        <ul>
          <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
          <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jal 20, 2020</time></a></li>
          <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
        </ul>
      </div>

      <div className="entry-content">
        <p>
        Radio being used as of the great tools to pass information to the Farmers during the Covid 19 Pandemic
          </p>
        <div className="read-more">
          <a href="blog-single.html">Read More</a>
        </div>
      </div>

    </article>

    <article className="entry">

    <div className="entry-img">

      <ReactPlayer width={'100%'} className='youtubev' url={'https://youtu.be/F4ytP4Cxdbk'} controls={true}   />

    </div>

    <h2 className="entry-title">
      <a href="blog-single.html">Radio as a COVID prevention tool for farmers in Malawi</a>
    </h2>
    
    <div className="entry-meta">
      <ul>
        <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">Farm Radio Trust</a></li>
        <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jal 20, 2020</time></a></li>
        <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
      </ul>
    </div>

    <div className="entry-content">
      <p>Farm Radio Trust (FRT), a leading ICT based non-profit making organization in Malawi has been implementing a social accountability project in Chikwawa and Nsanje districts in southern Malawi since 2017, with funding from the Open Society Initiative for Southern Africa (OSISA). Recently, OSISA invited all its grant awardees in Southern Africa to a stakeholder consultative meeting in Maputo, Mozambique. FRT was represented at this meeting by its Chief Executive Officer (CEO), Mr. George Vilili. While there, George caught up with Tim Wise, an accomplished researcher who has done a lot of work for the OSISA Foundation. In this interview, George speaks with Tim about a book he was launching in Maputo, known as.. ‘Eating Tomorrow,’ in which he has documented agricultural investments in Southern Africa, specifically in countries where the OSISA Foundation is implementing social accountability work, such as Malawi where the government is implementing the Farm Input Subsidy Programme (FISP). Tim also tips Farm Radio on how the institution could scale up its social accountability work in Malawi through radio and other ICTs such as the mobile-based platforms.</p>
      <div className="read-more">
        <a href="blog-single.html">Read More</a>
      </div>
    </div>

  </article>
   






        <div className="blog-pagination">
        <ul className="justify-content-center">
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index + 1} className={currentPage === index + 1 ? "active" : ""}>
              <button onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>

        </div>

        <div className="col-lg-4">

          <div className="sidebar">

            <h3 className="sidebar-title">Search</h3>
            <div className="sidebar-item search-form">
              <form action="">
                <input type="text"/>
                <button type="submit"><i className="bi bi-search"></i></button>
              </form>
            </div>
            <Link to="/signin" ><h5 className="sidebar-title">UPLOAD </h5></Link>

            <h3 className="sidebar-title">Categories</h3>
            <div className="sidebar-item categories">
              <ul>
                <li><a href="#">General <span>(13)</span></a></li>
                <li><a href="#">FRT<span>(6)</span></a></li>
                <li><a href="#">FHL<span>(4)</span></a></li>
                <li><a href="#">MLIMI Radio<span>(3)</span></a></li>

              </ul>
            </div>

            <h3 className="sidebar-title">Recent Posts</h3>
            <div className="sidebar-item recent-posts">
            <div className="post-item clearfix">
                <img src="./image/blog/farmgateprices.png" alt=""/>
                <h4><a href="blog-single.html">Farm Gate Prices</a></h4>
                <time datetime="2020-01-01">April 25, 2024 </time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/singlepublications/cattles.jpg" alt=""/>
                <h4><a href="blog-single.html">FRT Supporting cattle Farmers</a></h4>
                <time datetime="2020-01-01">18 March, 2024 </time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/blog-recent-1.jpg" alt=""/>
                <h4><a href="blog-single.html">19th National Agriculture Fair</a></h4>
                <time datetime="2020-01-01">August 24, 2023 </time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/blog-recent-2.jpg" alt=""/>
                <h4><a href="blog-single.html">FRT And NPC Have Signed A Memo Of Understanding(MoU)</a></h4>
                <time datetime="2020-01-01">July 23, 2023</time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/blog-recent-3.jpg" alt=""/>
                <h4><a href="blog-single.html">Happy Africa Day</a></h4>
                <time datetime="2020-01-01">May 25, 2023</time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/blog-recent-4.jpg" alt=""/>
                <h4><a href="blog-single.html">Trainings</a></h4>
                <time datetime="2020-01-01">Jan 1, 2023</time>
              </div>

              <div className="post-item clearfix">
                <img src="./image/blog/blog-recent-5.jpg" alt=""/>
                <h4><a href="blog-single.html">Mlimi Radio Launch</a></h4>
                <time datetime="2020-01-01">March 25, 2023</time>
              </div>

            </div>

            <h3 className="sidebar-title">Tags</h3>
            <div className="sidebar-item tags">
              <ul>
                <li><a href="#">ICT plartforms</a></li>
                <li><a href="#">mlimi App</a></li>
                <li><a href="#">services</a></li>
                <li><a href="#">Mlimi Radio</a></li>
                <li><a href="#">Design</a></li>
                <li><a href="#">Offices</a></li>
                <li><a href="#">Innovations</a></li>
                <li><a href="#">Studio</a></li>

                <li><a href="#">Tips</a></li>
                <li><a href="#">Marketing</a></li>
              </ul>
            </div>

          </div>

        </div>

      </div>

    </div>
  </section>
    
    </>
  )
}

export default Publications