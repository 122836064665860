import React from 'react'
import img from "../../images/phonem.png"
import Back from '../../common/Back'
import PolicyContent from "./PolicyContent"

import Header from '../../common/header/Header'

const Executive = () => {
  return (
    <>
    <Header/>
    <Back name='The The Policy for Mlimi mobile application' title='Mlimi-App Policy' cover={img} />
    
    <PolicyContent/>
    
    
    
    
    </>
  )
}

export default Executive