import React from "react";
import { Card, CardContent, Typography, Container, Divider, List, ListItem, ListItemIcon, ListItemText ,Box } from "@mui/material";
import { FaExternalLinkAlt,FaCreditCard, FaChild,FaFileSignature, FaRetweet,FaBullhorn, FaGavel, FaShieldAlt, FaUserLock, FaFileContract, FaGlobe, FaClipboardList, FaUserShield, FaLock, FaDatabase, FaEye, FaEnvelope } from "react-icons/fa";

const sections = [
  { icon: <FaUserLock />, title: "1. Introduction", content:[ "Welcome to Mlimi App ('we,' 'our', or 'us'). Your privacy is important to us. ","This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our mobile application ('App'). By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service. By using the App, you consent to the practices described in this policy."] },
  { icon: <FaFileContract />, title: "2. Definitions and key terms", 
    content: [
      "To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:",
      "> Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.",
      "> Organization: when this policy mentions Organization,” “we,” “us,” or “our,” it refers to Farm Radio MW, Plot number 862 Area 47 Sector 4 Off Msokela Road,from Bwandilo, Mazengera Street, Lilongwe that is responsible for your information under this Privacy Policy.",
      "> Country: where Mlimi App -  the owners/founders of Mlimi App - a Farmers App  based, in this case is Malawi",
      "> Customer: refers to the company, organization or person that signs up to use the Mlimi App - Farmers/Agriculture App Service to manage the relationships with your consumers or service users.",
      "> Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit Mlimi - Farmers App and use the services.",
      "> IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.",
      "> Personnel: refers to those individuals who are employed by Mlimi - Farmers App or are under contract to perform a service on behalf of one of the parties.",
      "> Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.",
      "> Service: refers to the service provided by Mlimi - Farmers App as described in the relative terms (if available) and on this platform.",
      "> Website: Mlimi - Farmers App's site, which can be accessed via this URL:https://farmradiomw.org/",
      "> You: a person or entity that is registered with Mlimi - Farmers App to use the Services.",
      
    ]
  },

  
  { icon: <FaFileContract />, title: "3. How Do We Use The Information We Collect?", 
    content: [
      "Any of the information we collect from you may be used in one of the following ways:",
      "> Personalize user experience and content(your information helps us to better respond to your individual needs).",
      "> To improve our app (we continually strive to improve our app offerings based on the information and feedback we receive from you)",
      "> To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)",
      "> To administer a contest, promotion, survey or other site feature",
      "> To send periodic emails",

      
    ]
  },
  { icon: <FaFileContract />, title: "4. When does Mlimi - Farmers App use end user information from third parties?", 
    content: [
      "Mlimi - Farmers App will collect End User Data necessary to provide the Mlimi - Farmers App services to our customers.",
      "End users may voluntarily provide us with information they have made available on social media websites. If you provide us with any such information, we may collect publicly available information from the social media websites you have indicated. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.",
      
    ]
  },

  { icon: <FaUserLock />, title: "5. When does Mlimi - Farmers App use customer information from third parties?", content: ["We receive some information from the third parties when you contact us. For example, when you submit your email address to us to show interest in becoming a Mlimi - Farmers App customer, we receive information from a third party that provides automated fraud detection services to Mlimi - Farmers App. We also occasionally collect information that is made publicly available on social media websites. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings."] },
//   { icon: <FaUserShield />, title: "Do we share the information we collect with third parties?", 
//     content: [
//       "> We may share the information that we collect, both personal and non-personal, with third parties such as advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you. We may also share it with our current and future affiliated companies and business partners, and if we are involved in a merger, asset sale or other business reorganization, we may also share or transfer your personal and non-personal information to our successors-in-interest.",
//       "> We may engage trusted third party service providers to perform functions and provide services to us, such as hosting and maintaining our servers and the app, database storage and management, e-mail management, storage marketing, credit card processing, customer service and fulfilling orders for products and services you may purchase through the app. We will likely share your personal information, and possibly some non-personal information, with these third parties to enable them to perform these services for us and for you.",
//       "> We may share portions of our log file data, including IP addresses, for analytics purposes with third parties such as web analytics partners, application developers, and ad networks. If your IP address is shared, it may be used to estimate general location and other technographics such as connection speed, whether you have visited the app in a shared location, and type of the device used to visit the app. They may aggregate information about our advertising and what you see on the app and then provide auditing, research and reporting for us and our advertisers. We may also disclose personal and non-personal information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process",
//       "> (including subpoenas), to protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules and regulations.",
     
//     ]
//   },
  
  { icon: <FaFileContract />, title: "6. Where and when is information collected from customers and end users?", content: ["Mlimi - Farmers App will collect personal information that you submit to us. We may also receive personal information about you from third parties as described above."] },
  { icon: <FaGlobe />, title: "7. How Long Do We Keep Your Information?", content: ["We do not sell your personal data. Security measures are in place to protect your information."] },
  { icon: <FaClipboardList />, title: "8. User Rights", content:[ "We keep your information only so long as we need it to provide Mlimi - Farmers App to you and fulfill the purposes described in this policy. This is also the case for anyone that we share your information with and who carries out services on our behalf. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we’ll either remove it from our systems or depersonalize it so that we can't identify you."] },
  { icon: <FaUserShield />, title: "9. How Do We Protect Your Information?", 
    content: [
      "We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. ",
      "After a transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept on file. We cannot, however, ensure or warrant the absolute security of any information you transmit to Mlimi - Farmers App or guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.",

    ]
  },
  { icon: <FaLock />, title: "10. Could my information be transferred to other countries?", content: ["Mlimi - Farmers App is incorporated in Malawi. Information collected via our Application, through direct interactions with you, or from use of our help services may be transferred from time to time to our offices or personnel, or to third parties, located throughout the world, and may be viewed and hosted anywhere in the world, including countries that may not have laws of general applicability regulating the use and transfer of such data. To the fullest extent allowed by applicable law, by using any of the above, you voluntarily consent to the trans-border transfer and hosting of such information."] },
  { icon: <FaLock />, title: "11. Is the information collected through the Mlimi - Farmers App Service secure?", content: [" We take precautions to protect the security of your information. We have physical, electronic, and managerial procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly use your information. However, neither people nor security systems are foolproof, including encryption systems. In addition, people can commit intentional crimes, make mistakes or fail to follow policies. Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute security. If applicable law imposes any non-disclaimable duty to protect your personal information, you agree that intentional misconduct will be the standards used to measure our compliance with that duty."] },
  { icon: <FaUserShield />, title: "12. HCan I update or correct my information?", 
    content: [
      "The rights you have to request updates or corrections to the information Mlimi - Farmers App collects depend on your relationship with Mlimi - Farmers App. Personnel may update or correct their information as detailed in our internal company employment policies. ",
      "Customers have the right to request the restriction of certain uses and disclosures of personally identifiable information as follows. You can contact us in order to (1) update or correct your personally identifiable information, (2) change your preferences with respect to communications and other information you receive from us, or (3) delete the personally identifiable information maintained about you on our systems (subject to the following paragraph), ",
      " by cancelling your account. Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion. To protect your privacy and security, we may take reasonable steps (such as requesting a unique password) to verify your identity before granting you profile access or making corrections. You are responsible for maintaining the secrecy of your unique password and account information at all times.",
        "You should be aware that it is not technologically possible to remove each and every record of the information you have provided to us from our system. The need to back up our systems to protect information from inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate. Promptly after receiving your request, all personal information stored in databases we actively use, and other readily searchable media will be updated, corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably and technically practicable. If you are an end user and wish to update, delete, or receive any information we have about you, you may do so by contacting the organization of which you are a customer.",
    ]
  },
  { icon: <FaUserShield />, title: "13. Personnel", 
    content: [
      "If you are a Mlimi - Farmers App worker or applicant, we collect information you voluntarily provide to us. We use the information collected for Human Resources purposes in order to administer benefits to workers and screen applicants. ",
      " You may contact us in order to (1) update or correct your information, (2) change your preferences with respect to communications and other information you receive from us, or (3) receive a record of the information we have relating to you. Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion.",
     ]
  },
  
  
  
  
  
  { icon: <FaLock />, title: "14. Sale of Business", content: ["We reserve the right to transfer information to a third party in the event of a sale, merger or other transfer of all or substantially all of the assets of Mlimi - Farmers App or any of its Corporate Affiliates (as defined herein), or that portion of Mlimi - Farmers App or any of its Corporate Affiliates to which the Service relates, or in the event that we discontinue our business or file a petition or have filed against us a petition in bankruptcy, reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy."] },
  { icon: <FaLock />, title: "15. Affiliates", content: ["We may disclose information (including personal information) about you to our Corporate Affiliates. For purposes of this Privacy Policy, 'Corporate Affiliate' means any person or entity which directly or indirectly controls, is controlled by or is under common control with Mlimi - Farmers App, whether by ownership or otherwise. Any information relating to you that we provide to our Corporate Affiliates will be treated by those Corporate Affiliates in accordance with the terms of this Privacy Policy."] },

  {
    "icon": <FaGavel />,
    "title": "16. Governing Law",
    "content": [
      "This Privacy Policy is governed by the laws of Malawi without regard to its conflict of laws provision. You consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between the parties under or in connection with this Privacy Policy except for those individuals who may have rights to make claims under Privacy Shield, or the Swiss-US framework.",
      "The laws of Malawi, excluding its conflicts of law rules, shall govern this Agreement and your use of the app. Your use of the app may also be subject to other local, state, national, or international laws.",
      "By using Mlimi - Farmers App or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, you should not engage with our website, or use our services. Continued use of the website, direct engagement with us, or following the posting of changes to this Privacy Policy that do not significantly affect the use or disclosure of your personal information will mean that you accept those changes."
    ]
   
},
{
  "icon": <FaShieldAlt />,
  "title": "17. Your Consent",
  "content": [
    "We've updated our Privacy Policy to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our app, registering an account, or making a purchase, you hereby consent to our Privacy Policy and agree to its terms.",

  ]
},
{
  "icon": <FaExternalLinkAlt />,
  "title": "18. Links to Other Websites",
  "content": [
    "This Privacy Policy applies only to the Services. The Services may contain links to third-party websites, which have their own privacy policies.",
    "Your use of those websites is subject to their respective terms, and we are not responsible for their content, policies, or practices.",
    "Third parties may use their own cookies or tracking methods to collect information about you. We encourage you to read their privacy policies."
  ]
},
{
  "icon": <FaBullhorn />,
  "title": "19. Advertising",
  "content": [
    "This app may contain third party advertisements and links to third party sites. Mlimi - Farmers App does not make any representation as to the accuracy or suitability of any of the information contained in those advertisements or sites and does not accept any responsibility or liability for the conduct or content of those advertisements and sites and the offerings made by the third parties",
    "Advertising keeps Mlimi - Farmers App and many of the websites and services you use free of charge. We work hard to make sure that ads are safe, unobtrusive, and as relevant as possible.",
    "Third party advertisements and links to other sites where goods or services are advertised are not endorsements or recommendations by Mlimi - Farmers App of the third party sites, goods or services. Mlimi - Farmers App takes no responsibility for the content of any of the ads, promises made, or the quality/reliability of the products or services offered in all advertisements.",

  ]
},
{
  "icon": <FaShieldAlt />,
  "title": "20. Cookies for Advertising",
  "content": [
    "These cookies collect information over time about your online activity on the app and other online services to make online advertisements more relevant and effective to you. This is known as interest-based advertising. They also perform functions like preventing the same ad from continuously reappearing and ensuring that ads are properly displayed for advertisers. Without cookies, it’s really hard for an advertiser to reach its audience, or to know how many ads were shown and how many clicks they received.",
    
  ]
},
{
    "icon": <FaShieldAlt />,
    "title": "21. Cookies",
    "content": [
      "Mlimi - Farmers App uses 'Cookies' to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of our app but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the app as we would not be able to remember that you had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies.",
      
    ]
  },
{
  "icon": <FaUserShield />,
  "title": "22. Blocking Cookies",
  "content": [
    "You can control how cookies are used through your browser settings.",
    "Blocking cookies may affect website functionality, including saving login details and site preferences.",
    "Without cookies, some features such as video playback or staying signed in may not work properly.",
    "Keep in mind that completely blocking cookies may prevent you from using certain features and services on our platform."
  ]
},


  {
    "icon": <FaRetweet />,
    "title": "23. Remarketing Services",
    "content": "We use remarketing services. What Is Remarketing? In digital marketing, remarketing (or retargeting) is the practice of serving ads across the internet to people who have already visited your website. It allows your company to seem like they're “following” people around the internet by serving ads on the websites and platforms they use most"
  },
  {
    "icon": <FaCreditCard />,
    "title": "24. Payment and Security",
    "content": "We are committed to your security. Transactions are protected using encryption and secure servers. While we take precautions, we cannot guarantee absolute security."
  },
  {
    "icon": <FaChild />,
    "title": "25. Children’s Privacy",
    "content": "We do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers."
  },
  {
    "icon": <FaFileSignature />,
    "title": "26. Changes to Privacy Policy",
    "content": "We may change our Service and policies, and we may need to make changes to this Privacy Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Privacy Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete your account"
  },
  {
    "icon": <FaExternalLinkAlt />,
    "title": "27. Third-Party Services",
    "content": "We may use third-party content or services. However, we are not responsible for their accuracy, compliance, or quality. Please review their policies before engaging with them."
  },
  {
    "icon":<FaEye />,
    "title": "28. Tracking and Cookies",
    "content":" We use 'Sessions' to identify the areas of our website that you have visited. A Session is a small piece of data stored on your computer or mobile device by your web browser."
  },
  {
    "icon": <FaShieldAlt />,
    "title": "29. GDPR Compliance",
    "content": "We collect and process data fairly, legally, and transparently. You have rights regarding your data, including access, correction, and deletion under GDPR regulations."
  },


  { icon: <FaDatabase />, title: "30. What Information Do We Collect?", content: ["Name / Username", "Phone Number", "Age", "Password", "Location (GPS) (optional)", "Camera (Pictures) (optional)", "Photo Gallery (optional)"] },
  { icon: <FaLock />, title: "31. How Do We Use The Information?", content: "We use the data to personalize your experience, improve services, provide support, and send notifications." },
  { icon: <FaEye />, title: "32. Do We Share Your Information?", content: "No, we do not share your information with third parties." },
  { icon: <FaShieldAlt />, title: "33. Security Measures", content: "We use SSL encryption and other security measures to protect your data." },
  { icon: <FaEnvelope />, title: "34. Contact Us", content: ["Email: info@farmradiomw.org/", "Phone: +265 993 44 92 45", "Website: https://farmradiomw.org ","Physical Address : Plot number 862 Area 47 Sector 4 Off Msokela Road [from Bwandilo, Mazengera Street]"] }
];

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 5 }}>
      <Card sx={{ p: 3, boxShadow: 3, borderRadius: 3 }}>
                {/* First Typography with Apple Green and Black Background */}
                <Box sx={{ 
                    background: "linear-gradient(90deg, #8DB600, black)", 
                    p: 2, 
                    borderRadius: 2, 
                    color: "white",
                    textAlign: "center"
                  }}>
                    <Typography variant="h4" align="center">
                       Privacy Policy
                    </Typography>
                    <p>Updated at March 13th, 2025</p>
                  </Box>
        <Divider sx={{ mb: 3 }} />
        {sections.map((section, index) => (
          <CardContent key={index}>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
               {section.title}
            </Typography>
            {Array.isArray(section.content) ? (
              <List>
                {section.content.map((item, i) => (
                  <ListItem key={i} sx={{ pl: 2 }}>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
                {section.content}
              </Typography>
            )}
            {index < sections.length - 1 && <Divider sx={{ my: 2 }} />}
          </CardContent>
        ))}
          {/* Last Typography with Apple Green and Black Background */}
          <Box sx={{ 
            background: "linear-gradient(90deg, black, #8DB600)", 
            p: 2, 
            borderRadius: 2, 
            color: "white",
            textAlign: "center",
            mt: 3
          }}>
            <Typography variant="body2" align="center">
              Last updated: 13 March 2025
            </Typography>
          </Box>
      </Card>
    </Container>
  );
};

export default PrivacyPolicy;